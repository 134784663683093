import { LangCode } from "@/types/lang";

export const availableLangs = [
  {
    title: "English",
    code: LangCode.ENGLISH,
  },
  {
    title: "Русский",
    code: LangCode.RUSSIAN,
  },
  {
    title: "Українська",
    code: LangCode.UKRAINIAN,
  },
  {
    title: "Español",
    code: LangCode.SPANISH,
  },
];
