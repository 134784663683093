import en from "./en";
import ru from "./ru";
import uk from "./uk";
import es from "./es";

export default {
  en,
  ru,
  uk,
  es,
};
