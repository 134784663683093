import axios from "axios";

export default async function updateTransactionMonitoring(payload: string[]) {
  const { data } = await axios.post(
    "/admin/transaction-monitoring/update",
    {
      data: payload,
    },
    {
      isAxiosNotify: false,
    }
  );

  return data;
}
