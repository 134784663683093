import { ORIGIN } from "@config/base";
import { telegramSupportBotLink } from "@config/social";
import uk from "vuetify/src/locale/uk";

export default {
  ...uk,
  dictionary: {
    country: {
      tr: "Турция",
      ee: "Эстония",
      us: "США",
    },
  },
  dataTable: {
    ...uk.dataTable,
    itemsPerPageText: "Рядків сторінки",
  },
  noDataText: "Дані відсутні",
  warnings: {
    cards_suspended_update_status: `<b>Картки заморожено</b> - Щоб продовжити роботу з картками, зніміть їх зі статусу "Заморожено".`,
    overdraft_admedia_prepaid_card:
      "Обратите внимание, карта может выйти в минус на {0} из-за комиссии за международные платежи!",
    overdraft_admedia_postpaid_card:
      "Обратите внимание, транзакции могут пройти выше указанного лимита на {0} из-за комиссии за международные платежи!",
  },
  product_tour: {
    prev_btn_text: "Предыдущий",
    next_btn_text: "Следующий",
    done_btn_text: "Завершить",
    progress_text: "{{current}} из {{total}}",
  },
  errors: {
    can_choose_max: "Макс. возможное кол-во - {0} шт.",
    promocode_not_valid: "Введенный промокод недействителен.",
    promocode_access_blocked:
      "Превышено максимальное количество неудачных попыток. Попробуйте позднее.",
    bank_inquiry_disabled: "Цей BIN відключено на вашому акаунті",
    invalid_invite: "Запрошення недійсне або застаріло.",
    internal_error: "Внутрішня помилка",
    no_internet_connection: "Немає підключення до інтернету",
    invalid_telegram_login:
      "Будь ласка, введіть свій Telegram логін. Наприклад, @example",
    invalid_current_password: "Поточний пароль неправильний",
    invalid_promocode: "Промокод не існує або недійсний",
    limit_amount_card_issue:
      "Сума ліміту повинна бути більшою за кількість активних карток ({0})",
    positive_number: "Вкажіть додатне число",
    only_digits: "Вкажіть числове значення",
    only_integer_number: "Вкажіть ціле число",
    min_amount_refill: "Мінімальна сума поповнення складає {0}",
    min_amount_transfer: "Минимальная сумма перевода составляет {0}",
    minimal_card_balance_cannot_be_less:
      "Минимальный остаток на карте не может быть меньше {0}",
    min_amount: "Мінімальна сума {0}",
    max_amount: "Максимальна сума {0}",
    min_value: "Мінімальне значення {0}",
    max_value: "Максимальне значення {0}",
    max_сhars: "Максимальное количество символов: {0}",
    try_later: "Виникла помилка. Будь ласка, спробуйте пізніше",
    access_denied: "Ваші дані для входу помилкові",
    user_already_exists: "Користувач вже існує",
    invalid_code: "Неправильний код",
    too_many_postpaid_card_issued: `Вы достигли максимального количества лимитных карт. Обратитесь, пожалуйста, в нашу поддержку: <a href="${telegramSupportBotLink}" target="_blank">@multicards_support_bot</a>.`,
    too_many_card_issued_mediabuyer:
      "Ви досягли максимальної кількості активних карток. Зверніться, будь-ласка, до свого Тімліда для збільшення ліміту.",
    too_many_card_issued_teamlead: `Ви досягли максимальної кількості активних карт. Зверніться, будь-ласка, до нашої підтримки: <a href="${telegramSupportBotLink}" target="_blank">@multicards_support_bot</a>.`,
    bank_not_available_support: `Цей банк поки що Вам недоступний. Будь ласка, зверніться до нашої підтримки <a href="${telegramSupportBotLink}" target="_blank">@multicards_support_bot</a>.`,
    user_account_not_found: "Обліковий запис користувача не знайдено",
    required: "Необхідно для заповнення",
    email: {
      incorrect: "Помилковий адрес електронної пошти",
      try_with_another_domain: "Будь ласка, спробуйте e-mail з іншим доменом",
    },
    password: {
      max_number_change_requests:
        "Превищено максимальну кількість запитів на зміну паролю. Спробуйте, будь-ласка, пізніше ",
      must_match: "Пароль повинен співпадати",
      length:
        "Довжина пароля має бути від 8 до 20 латинських символів, включно з однією цифрою та однією заголовною буквою",
    },
    insufficient_balance: "Недостатньо коштів на балансі",
    insufficient_balance_user: "Недостатньо коштів на рахунку користувача {0}",
    image_size: "Розмір зображення не повинен перевищувати {0}кб",
    sms_confirmation_verify_failed: "Неправилний код підтвердження",
    sms_verification_max_attempts_error:
      "Вы достигли максимального количества попыток на смену номера телефона. Пожалуйста, попробуйте позднее",
    card: {
      max_limit_you_can_set:
        "Максимальный лимит, который вы можете установить {0}",
      limit_balance: "Ви повинні мати як мінімум {0} на балансі",
      total_daily_limit:
        "Загальний ліміт повинен бути більше або дорівнює денному ліміту",
    },
    phone_number: {
      required: "Заповніть номер телефону",
      busy_and_try_another:
        "Цей номер телефону не може використовуватися на нашому сервісі. Будь ласка, спробуйте ввести інший номер",
    },
  },
  info: {
    transferred_5_minutes: "{0} будут переведены в течение 5 минут!",
    promocode_successfully_applied: "Промокод успешно применен!",
    copy_to_clipboard: "Натисніть {0} для копіювання",
    contact_support_get_api_doc:
      "Для получення API doc. зверніться, будь-ласка, до підтримки",
    set_limit_amount_success: "Ліміт успішно встановлено",
    changes_saved: "Ваші зміни збережено",
    password_changed: "Ваш пароль успішно змінено",
    refill_user_success: "Баланс {0} поповнено на {1}",
    card_refilled: "Картка поповнена",
    sure_want_refill_card: "Впевнені, що хочете поповнити картку на {0} ?",
    sure_want_refill_account:
      "Впевнені, що бажаєте поповнити баланс користувача {0} на {1} ?",
    copied: "Скопійовано",
    copy: "Копіювати",
    copy_card_number: "Копіювати номер картки",
    card_created: "Картка успішно створена",
    download_to_csv: "Завантажити у CSV",
    download_to_excel: "Завантажити у EXCEL",
    deleted: "Видалений",
    pay_attention_for_advertising_purposes: `
      Зверніть увагу, що користуватися картками можна лише для оплати рекламних кабінетів <span class="white--text">({0})</span>.
      За сплачування сторонніх сервісів обліковий запис може бути заблокований.
    `,
    commission_canceled_transactions_non_refundable:
      "Комісію за скасовані транзакції не буде повернено.",
    card_frozen_no_transactions_more_two_weeks:
      "Якщо на карті не було транзакцій більше двох тижнів з моменту випуску, то картка заморожується. Для активної роботи з карткою ліміти потрібно переставити вручну.",
    declension: {
      cards: {
        i_1: "карта",
        i_2: "карты",
        i_3: "карт",
        i_4: "карту",
        close: {
          i_1: "закрыта",
          i_2: "закрыто",
          i_3: "закрыто",
        },
        freeze: {
          i_1: "поставлена на паузу",
          i_2: "поставлено на паузу",
          i_3: "поставлено на паузу",
        },
        unfreeze: {
          i_1: "снята с паузы",
          i_2: "снято с паузы",
          i_3: "снято с паузы",
        },
        unsuspend: {
          i_1: "разморожена",
          i_2: "разморожено",
          i_3: "разморожено",
        },
      },
    },
  },
  locked_page: {
    team: {
      text: `Розділ <b>"Команда"</b> поки що Вам недоступний.<br />
        Для відкриття даного розділу, зверніться, будь ласка, до нашої підтримки:`,
    },
    referral_program: {
      text: `
        Розділ <b>"Реферальна програма"</b> поки для вас недоступний.<br />
        Для відчинення данного розділу даного розділу, зверніться, будь-ласка, до нашої підтримки:
      `,
    },
  },
  promocode: {
    type: {
      free_cards_bonus: "Free cards",
      deposit_bonus: "Deposit bonus",
    },
    status: {
      completed: "Использован",
      active: "Активный",
    },
  },
  team: {
    overall_balance: "Загальний баланс команди",
    sure_want_member: "Ви впевнені, що хочете видалити {0} із команди?",
    status: {
      active: "Активний",
      deleted: "Видалено",
    },
  },
  telegramBanner: {
    title: "Доєднуйтеся до нашого telegram!",
    subtitle: "Будь в курсі свіжих новин <span>multicards.io</span>.",
  },
  messages: {
    facebookCode: {
      title: "{0} - код Facebook",
      text: "Данный код относится к оплате по карте {0}",
    },
  },
  fields: {
    to_be_paid: "К выплате",
    total_paid: "Всего выплачено",
    paid: "Сплачено",
    apply: "Применить",
    promocode_name: "Название промокода",
    mediabuyer: "Медиабайер",
    teamlead: "Тимлид",
    max_number_of_cards: "Макс. кількість карт",
    out_of: "із",
    no_limit: "Без ліміту",
    no_daily_limit: "Без денного ліміту",
    no_total_limit: "Без загального ліміту",
    number_of_cards: "Кількість карток",
    daily_limit: "Денний ліміт",
    total_limit: "Загальний ліміт",
    limit: "Ліміт",
    deposit_amount: "Сумма поповнення",
    deposit_amounts: "Сумма поповнень",
    deposit_amount_per_card: "Сумма поповнення картки",
    card_name: "Название карты",
    promocode: "Promocode",
    history_promocodes: "История промокодов",
    min_commission: "Мін. комісія",
    commission_rate: "Коміссія",
    card_issue: "Випуск картки",
    confirmation_code: "Код підтвердження",
    firstName: "Ім'я",
    lastName: "Прізвище",
    password: "Пароль",
    new_password: "Новий пароль",
    repeat_new_password: "Повторіть новий пароль",
    confirm_password: "Повторіть пароль",
    forgot_password: "Забули пароль?",
    continue: "Продовжити",
    back: "Назад",
    phone: "Номер телефону",
    notification: "Сповіщення",
    cancel: "Скасувати",
    next: "Далі",
    save_changes: "Зберегти зміни",
    change: "Змінити",
    connect: "Підключити",
    disable: "Вимкнути",
    logout: "Вийти",
    auth_2auth: "2FA аутентифікація",
    your_password: "Ваш пароль",
    current_password: "Поточний пароль",
    code_2fa: "2FA-код",
    notifications: "Повідомлення",
  },
  error_page: {
    iternal_server_error: {
      subtitle: "Внутрішня помилка серверу",
      text: "Сервер знайшов внутрішню помилку або невірну конфігурацію та не зміг виконати ваш запит",
    },
    page_not_found: {
      subtitle: "Сторінку на знайдено",
      text: "Сторінка, яку ви шукаєте, могла бути видалена через зміну назви або тимчасово недоступна",
    },
    service_unavailable: {
      subtitle: "Система на обслуговуванні",
      text: "Ми усуваємо проблеми, що виникли. Найближчим часом робота сервісу відновиться",
    },
    back_to_home: "Повернутися на головну",
  },
  cookies: {
    text1:
      "У вас є можливість прийняти або відхилити файли cookie. Більшість веб-браузерів автоматично приймають файли cookie, але ви можете змінити налаштування свого браузера, щоб відхилити файли cookie, якщо забажаєте. Однак зауважте, що вимкнення файлів cookie може вплинути на вашу здатність отримати доступ до певних функцій або функцій веб-сайту.",
    text2:
      "Щоб керувати налаштуваннями файлів cookie, ви можете змінити налаштування власного браузера або скористатися інструментом керування згодою на файли cookie, доступним на нашому веб-сайті.",
    accept: "Прийняти",
  },
  landing: {
    nav: {
      benefits: "Переваги",
      contact_us: "Зв'язятися з нами",
      get_started: "Розпочати",
      log_in: "Авторизуватися",
    },
    into: {
      title: "Найкращі віртуальні картки для",
      subtitle:
        "Ідеальне та просте рішення для онлайн-маркетингу та електронної комерції.",
      learn_more: "Дізнатися більше",
      list: {
        item1: {
          title: "Низькі тарифи",
          subtitle:
            "Більше витрат – менше комісії. Безлімітні картки. Поповнюйте криптовалютою та за допомогою wire переказів.",
        },
        item2: {
          title: "Працюйте зі своєю командою",
          subtitle:
            "Додавайте членів вашої команди до групи та разом працюйте з картками та бюджетами.",
        },
        item3: {
          title: "Систематична аналітика",
          subtitle:
            "Отримуйте персональні звіти та детальну статистику витрат для кожного члена команди.",
        },
      },
    },
    get_started: {
      title: "Multicards – це просто і зручно",
      text1:
        "Коли ваш акаунт буде схвалено, ви зможете додати кошти на свій баланс та створити свою першу картку.",
      text2: "Ви можете налаштувати та розпочати свої кампанії де завгодно!",
      users: {
        text1: "",
        text2: "Користувачів<br>вже доєдналися",
        count: "10,5 тис.",
      },
      list: {
        item1: {
          title: "Поповнюйте<br>свій баланс",
          subtitle:
            "Зручні способи поповнення балансу за допомогою криптовалюти або wire переказу.",
        },
        item2: {
          title: "Керуйте своєю кампанією",
          subtitle:
            "Наш зручний інтерфейс дозволить вам швидко та ефективно розпочати свою кампанію.",
        },
        item3: {
          title: "Аналізуйте детальну історію транзакцій",
          subtitle:
            "Ви матимете всю історію транзакцій з можливістю завантаження даних.",
        },
      },
    },
    benefits: {
      list: {
        item1: {
          title: "Картки,<br>яким можна довіряти",
          text: "Понад 7 БІН з безлімітніми картками. Випускайте картку миттєво!",
        },
        item2: {
          title: "Картки<br>з лімітом",
          text: "Керуйте щоденним i загальним лімітом для карток або використовуйте баланс свого рахунку як ліміт.",
        },
        item3: {
          title: "Картки<br>для ваших потреб",
          text: "Наші картки оптимізовано для Facebook, Google, TikTok та інших рекламних платформ.",
        },
        item4: {
          title: "Детальна<br>обробка",
          text: "Ви побачите всі транзакції в режимі реального часу, відображені в нашій системі.",
        },
        item5: {
          title: "Обслуговування<br>24/7",
          text: "У будь-який час, незалежно від режиму роботи банку.",
        },
        item6: {
          title: "Захищено<br>на 100%",
          text: "Двохфакторна автентифікація та контроль витрат для вас i вашої команди.",
        },
      },
    },
    contact_us: {
      title: "Зв'язятися з нами",
      subtitle:
        "Якщо у вас є запитання, ви завжди можете <span>зв’язатися</span> з нашою службою <span>підтримки</span>, ми розповімо набагато більше про наші <span>переваги</span>.",
    },
    partners: {
      subtitle: "Наши партнеры",
    },
    footer: {
      title: "Підписуйтесь!",
      subtitle:
        "Приєднуйтесь до нашої розсилки та будьте завжди в курсі наших останніх новин.",
      follow_us: "Слідкувати:",
      terms_of_use: "Умови використання",
      privacy_policy: "Політика конфіденційності",
      copyright:
        "© {0} Multicards. Усі права захищено. DIGITORY GROUP PC, Великобританія.",
    },
  },
  login: {
    title: "Ласкаво просимо!",
    subtitle: "Увійдіть, щоби продовжити",
    dont_have_account: "Немає аккаунту?",
    join: "Доєднуйтеся до Multicards.io",
  },
  sign_up: {
    title: "Ласкаво просимо!",
    subtitle: "Зареєструйтесь, щоб продовжити",
    have_account: "Вже є аккаунт?",
    login: "Пройдіть авторизацію",
    register: "Зареєструватися",
  },
  forgot_password: {
    title: "Забули свій пароль?",
    send_code_again: "Надіслати код повторно",
  },
  subscribe: "Підписатися",
  verify_account: {
    title: "Вітаю!",
    text1: `Ми раді, що ви зареєструвалися на <a href="${ORIGIN}" target="_blank">multicards.io</a>!`,
    text2: `Щоб почати користуватися <a href="${ORIGIN}" target="_blank">multicards.io</a>, нам необхідно верифікувати ваш обліковий запис. Для цього зв'яжіться, будь-ласка, з нами через telegram:`,
  },
  confirm_email: {
    title: "Подтвердите почту для аккаунта",
    text1: `Добро пожаловать на сервис <a href="${ORIGIN}" target="_blank">multicards.io</a>!`,
    text2:
      "Мы выслали вам ссылку для подтверждения аккаунта на вашу почту: <b>{0}</b>",
    resend_link: "Повторно отправить ссылку",
  },
  blocked_account: {
    title: "Доброго дня!",
    text1:
      "Ваш обліковий запис заблоковано. Зараз ви не зможете увійти та користуватися цим акаунтом",
    text2: "Ви завжди можете зв'язатися з нами через telegram:",
  },
  feedback_survey: {
    title: "Ваше мнение важно для нас!",
    skip_survey: "Пропустить опрос",
    send_answer: "Отправить ответ",
    completion: {
      title: "Спасибо за ваш ответ!",
    },
    question_1: {
      text: `С баланса каких Партнерских Программ ты бы хотел иметь возможность вывода денежных средств для<br>пополнения <a href="${ORIGIN}" tabindex="-1" target="_blank">multicards.io</a>?`,
    },
    question_2: {
      text: `Какую платежную систему ты используешь помимо <a href="${ORIGIN}" tabindex="-1" target="_blank">multicards.io</a>?`,
    },
  },
  dashboard: {
    my_balance: "Мой баланс",
    contact_support: "Зв'яжіться з нами",
    main_product_tour: {
      buttons: {
        lets_start: "Давайте начнем!",
        skip_intro: "Пропустить обучение",
        finish_intro: "Закончить обучение",
        move_to_cards: `Перейти к разделу "Карты"`,
        move_to_balance_cards: `Перейти к разделу "Балансовые карты"`,
        move_to_limit_cards: `Перейти к разделу "Лимитные карты"`,
        move_to_refill: 'Перейти к разделу "Пополнение"',
        move_to_currency_converter: `Перейти к разделу "Конвертация"`,
        move_to_transactions: `Перейти к разделу "Транзакции"`,
        move_to_cash_flow: `Перейти к разделу "Поток средств"`,
        move_to_team: `Перейти к разделу "Команда"`,
        move_to_statistic: `Перейти к разделу "Статистика"`,
        move_to_profile: `Перейти к разделу "Профиль"`,
        move_to_additional_info: `Перейти к разделу "Доп. информация"`,
        watch_tutorial: "Пройти обучение",
      },
      sure_skip_intro: "Уверены, что хотите пропустить обучение?",
      step1: `
        Мы рады, что вы решили воспользоваться сервисом multicards.io.<br /><br />
        Мы подготовили для вас обучение по нашему сервису. Здесь мы расскажем коротко о всем функционале нашего сервиса.<br /><br />
        Уверены, что вам он понравится и вы останетесь довольны 🙂
      `,
      step2: `
        Сверху вы всегда будете видеть баланс вашего аккаунта.<br /><br />

        Обратите внимание, что мы разделили для вашего удобства балансы на:<br />
        - Основной баланс<br />
        - Карточный баланс (Денежные средства, которые лежат на картах)
      `,
      step3: `
        Здесь же находится общий показатель отклоненных платежей - Decline rate.<br /><br />
        Данный блок всегда поможет вам понять вашу статистику по отклоненным платежам.
      `,
      step4: `
        В разделе “Карты”, вы можете создавать как балансовые, так и лимитные карты.<br /><br />
        Изначально доступны только балансовые карты. Для открытия лимитных карт обратитесь к вашему аккаунт-менеджеру.
      `,
      step5:
        "В данном разделе есть поле для поиска карт по названию, настройка колонок, фильтрация карт по владельцу, БИН-у и статусу.",
      step6: "Вы можете сделать выгрузку по картам в двух форматах: CSV и XLS.",
      step7: `Для выпуска карты следует нажать на кнопку "Выпуск карты".`,
      step8:
        "В данном окне отображаются доступные для выпуска карты и основная информация по ним.",
      step9:
        "Далее вы можете выбрать любой подходящий вам БИН и попробовать выпустить карту.",
      step10:
        "В окне создания карты необходимо ввести название карты, сумму пополнения и общее количество карт.",
      step11: "Ниже вы увидите общую сумму операции по выпуску карт.",
      step12: "Условия для данного БИН-а указаны в данном блоке.",
      step13:
        "После создания балансовой карты вы можете увидеть основную информацию по ней: номер карты, CVC, статус, текущий баланс и расход.",
      step14:
        "Вы всегда можете пополнить баланс или снять денежные средства с балансовой карты.",
      step15: `Также есть удобная функция - "Автопополнение". С помощью данной функции вы можете настроить автоматическое пополнение балансовой карты.`,
      step16:
        "Около каждой карты есть бургер-меню, где скрывается дополнительный функционал.",
      step17: "Функция быстрого просмотра всех транзакций по данной карте.",
      step18: "Функция быстрого просмотра всей статистики по данной карте.",
      step19: `Функция "Пауза" для карты.`,
      step20: `Функция "Закрыть карту". После закрытия все денежные средства на карте вернутся на основной баланс.`,
      step21: ` 
        Похожий функционал кроется в разделе "Лимитные карты".<br />
        Главное различие - это то, что все транзакции списывают денежные средства с основного баланса.<br /><br />
        
        Карта настраивается через 2 значения:
        - Дневной лимит
        - Общий лимит
      `,
      step22: "Лимит карты настраивается вручную.",
      step23: `
        Здесь вы можете настроить лимиты на текущей карте или привязать данные лимиты к балансу аккаунта.<br /><br /><br />

        Если выставить значения "Без дневного лимита", то значение "Дневной лимит" будет приравниваться к балансу аккаунта.<br /><br />
        Если выставить значения "Без общего лимита", то значение "Общий лимит" будет приравниваться к балансу аккаунта.
      `,
      step24:
        'Во вкладке "Пополнение" вы всегда можете пополнить баланс вашего аккаунта.',
      step25: "Для пополнения доступны опции USDT и Capitalist. ",
      step26:
        "Мы рекомендуем пользоваться пополнением USDT TRC20, так как данный способ выгоднее, удобнее и быстрее.",
      step27:
        "В данном окне вы можете воспользоваться калькулятором и точно определить сумму, которая упадет на баланс.",
      step28:
        "Обязательно копируйте данный кошелек, именно он привязан к вашему аккаунту!",
      step29: `В подразделе “Конвертер” осуществляется конвертация денежных средств основного баланса.`,
      step30: "Доступны конвертации USD ⭢ EUR и EUR ⭢ USD.",
      step31: `В разделе "Транзакции" вы увидите все операции, которые касаются карт и карточного баланса.`,
      step32: `
        Сверху расположены основные фильтры:<br /><br />
        - Фильтр по дате<br />
        - Фильтр по пользователям<br />
        - Фильтр по картам
      `,
      step33: `
        Дополнительные фильтры расположены здесь:<br /><br />
        - Фильтр по валюте<br />
        - Фильтр по статусу<br />
        - Фильтр по типу транзакции
      `,
      step34: "Также доступна функция выгрузки отчета в CSV и XLS.",
      step35: "Пример транзакции.",
      step36: `
        В разделе "Поток средств" вы увидите все операции, которые касаются вашего основного баланса.<br /><br />

        Список всех операций:<br />
        - Конвертация валюты<br />
        - Комиссия за транзакцию<br />
        - Пополнение внутреннего баланса<br />
        - Пополнение карты<br />
        - Перевод с карты на основной баланс<br />
        - Перевод денежных средств<br />
        - Входящий перевод денежных средств<br />
        - Комиссия за создание карты<br />
        - Комиссия за отклоненный платеж<br />
        - Возврат по транзакции<br />
        - Возврат по транзакции с закрытой карты<br />
        - Закрытие карты. Возврат неиспользованных средств
      `,
      step37: `
        Сверху расположены основные фильтры:<br /><br />
        - Фильтр по дате<br />
        - Фильтр по пользователям
      `,
      step38: `
        Дополнительные фильтры расположены здесь:<br /><br />
        - Фильтр по валюте<br />
        - Фильтр по типу операции
      `,
      step39: "Также доступна функция выгрузки отчета в CSV и XLS.",
      step40: "Пример операции.",
      step41: `
        В разделе “Команда” осуществляется управление вашей командой.<br /><br />
        Для доступа к командному интерфейсу обратитесь к своему аккаунт-менеджеру.
      `,
      step42: `
        Здесь расположена пригласительная ссылка, которую вам нужно прислать вашему медиабайеру. Он автоматически прикрепится в вашу команду после регистрации.<br /><br />
        Ссылку можно скопировать или сгенерировать заново.
      `,
      step43:
        "Для каждого члена команды вы можете настроить лимит на количество активных карт.",
      step44:
        "Вы можете сделать пополнение своего медиабайера в данном разделе.",
      step45: "Также доступна функция автоматического пополнения медиабайера.",
      step46: `
        Вы всегда можете удалить медиабайера из команды.<br /><br />
        Обратите внимание, что все его карты автоматически закроются, а остаток баланса будет переведен на ваш аккаунт.
      `,
      step47: `В разделе “Статистика” отображается вся сводная информация о транзакциях по оплатам, холдам, поступлениям, возвратам и комиссиям.`,
      step48: `
        Сверху можно найти разные фильтры для вашего удобства:<br />
        - Фильтр по дате<br />
        - Фильтр по пользователям<br />
        - Фильтр по картам<br />
        - Фильтр по банкам<br />
        - Фильтр по валюте
      `,
      step49: `
        Особое внимание стоит уделить группировке.<br /><br />
        Данный отчет можно группировать как по членам команды, так и по дате.
      `,
      step50: "Также доступна функция выгрузки отчета в CSV и XLS.",
      step51: `Раздел "Профиль" содержит основную информацию по вашему аккаунту и несколько дополнительных функций.`,
      step52:
        "Вы можете привязать номер телефона к аккаунту для того, чтобы пользоваться БИН-ами с 3ds.",
      step53: `
        В целях безопасности есть возможность подключить 2FA.<br /><br />
        Рекомендуем использовать данный метод защиты вашего аккаунта.
      `,
      step54: `
        Также есть возможность выключить ограничение на 1 активную сессию на вашем аккаунте.<br /><br />
        Рекомендуем выключать лимит только при активном 2FA.
      `,
      step55: `
        Нажатие на эту кнопку перенаправит вас на support-бота сервиса Multicards.io.<br /><br />
        Наши менеджеры поддержки в кратчайшие сроки поможет решить любой вопрос касаемо работы сервиса.
      `,
      step56:
        "В разделе F.A.Q. есть ответы на все основные вопросы по работе с сервисом и его условия.",
      step57: `
        Здесь публикуются все важные новости multicards.io.<br /><br />
        В данном разделе вы всегда сможете увидеть актуальную информацию.
      `,
      step58: `
        Здесь наш краткий гайд подошел к концу!<br /><br />
        Мы стараемся делать все, чтобы вам было удобно работать с виртуальными картами.<br />
        Вы всегда можете написать в нашу поддержку или вашему личному аккаунт-менеджеру по любому вопросу!
      `,
    },
    users: {
      title: "Користувачі",
    },
    change_card_balance: {
      title: "Зміна баланса",
      from: "Від",
      to: "У",
    },
    auto_refill: {
      title: "Автоматичне поповнення",
      enabled: "Автоматичне поповнення увімкнено",
      disabled: "Автоматичне поповнення вимкнено",
      threshold: "Якщо баланс нижче",
    },
    confirmation_code: {
      request_again_via: "Запитати новий код через {0} с.",
      request_again: "Запитати код повторно",
      get: "Отримати код",
    },
    phone_number: {
      approved: "Телефон підтверджено",
      verify_phone: "Підтвердіть номер телефону",
      bind: "Прив'язати",
      sure_want_unbind: "Ви впевнені, що хочете відв'язатися від номеру  {0} ?",
      sure_want_change:
        "Ви впевнені, що хочете змінити поточний номер телефону на {0} ?",
      used_for_vidar: `Цей номер телефону використовується для надсилання 3ds-повідомлень. <br /> На даний момент це працює тільки для Vidar (EUR)`,
    },
    statistic: {
      groupBy: {
        label: "Групувати за",
        date: "Дате",
        mediabuyer: "Медіабаєру",
        teamlead: "Тімліду",
      },
    },
    welcome: {
      title: "Ми раді, що ви вирішили скористатися нашим сервісом!",
      refill: {
        text: "Перше поповнення до<br /><b>{0}</b> без комісії<br />спеціально для тебе!",
      },
      issueCard: {
        text: "Випуск <b>перших {0} карток</b> безкоштовно саме для тебе!",
      },
      support: {
        text: "Долучайся до нас в <b>telegram!</b><br />Будь в курсі новин.",
      },
    },
    user: {
      role: {
        accountant: "Акаунтер",
        teamlead: "Тімлід",
        owner: "Власник",
        mediabuyer: "Медіабаєр",
      },
    },
    limit_per_active_session: "Ліміт на 1 активну сесію",
    encrypt_card_data: "Шифровать данные карт",
    facebook_code_notifications: "Уведомлять по кодам Facebook",
    hide_team_members_cards: "Скрывать карты команды",
    change_profile_image: "Зміна фотографії профілю",
    avatar_objects: "Предмети",
    avatar_animals: "Тварини",
    avatar_people: "Людина",
    news: {
      title: "Новости",
      show_more: "Згорнути",
      show_less: "Розгорнути",
    },
    datepicker: {
      today: "Сегодня",
      yesterday: "Вчера",
      last_3_days: "Останні 3 дні",
      last_7_days: "Останні 7 днів",
      last_14_days: "Останні 14 днів",
      current_month: "Этот месяц",
      last_month: "Останній місяць",
      prev_month: "Прошлый месяц",
      last_3_months: "Останні 3 місяці",
      last_6_months: "Останні 6 місяців",
      custom: "Користувачський",
    },
    card: {
      batch_issue: {
        comming_soon: `
          Ваші картки вже майже готові!<br />
          Вони з'являться у розділі {icon} <b>«Картки»</b> незабаром.
        `,
        pending:
          'Групу карток <b>"{0}"</b> створюється. Будь ласка зачекайте...',
        success: 'Групу карток <b>"{0}"</b> створена!',
        confirm_cancel: {
          title:
            'Ви впевнені, що хочете призупинити випуск групи карток "{0}" ?',
        },
      },
      batch_update: {
        pending: {
          close_cards: "{0} / {1} карт закрыто.",
          freeze_cards: "{0} / {1} карт поставлено на паузу.",
          unfreeze_cards: "{0} / {1} карт снято с паузы.",
          unsuspend_cards: "{0} / {1} карт разморожено.",
        },
        success: {
          close_cards: "{0} {1} успешно {2}!",
          freeze_cards: "{0} {1} успешно {2}!",
          unfreeze_cards: "{0} {1} успешно {2}!",
          unsuspend_cards: "{0} {1} успешно {2}!",
        },
        are_you_sure: {
          close_cards: "Вы уверены, что хотите<br />закрыть {0} {1}?",
          freeze_cards:
            "Вы уверены, что хотите<br />поставить на паузу {0} {1}?",
          unfreeze_cards: "Вы уверены, что хотите<br />снять с паузы {0} {1}?",
          unsuspend_cards: "Вы уверены, что хотите<br />разморозить {0} {1}?",
          cancel_action: "Вы уверены, что хотите отменить действие?",
        },
      },
    },
    qrcode: {
      connect_info:
        "Наведіть на QR-код камеру телефону з додатку Google Authenticator та введіть отриманий код",
    },
    billing: {
      status: {
        created: "Створено",
        success: "Успішно",
        error: "Помилка",
        expired: "Сплив",
      },
      usdt_transfer: {
        title:
          "Ви можете поповнити свій рахунок за допомогою USDT переводу за вказаними реквізитами.",
        text1: `
          Інструкція з поповнення:<br/>
          1. Копіювати адресу поповнення<br/>
          2. Зробити поповнення за відповідними реквізитами<br/>
          3. Написати до підтримки <a href="${telegramSupportBotLink}" target="_blank">@multicards_support_bot</a><br/>
          4. Обов'язково надіслати менеджеру <b>хеш транзакції</b> (ідентифікатор транзакції)<br/>
        `,
        text2: `
          Звертаємо увагу, що ваш рахунок не буде поповнено, доки ми не отримаємо <b>підтвердження транзакції</b>.<br/>
          Мінімальне поповнення становить {0} USDT.
        `,
        amount_out: "Віддаєте {0}",
        amount_in: "Отримуєте {0}",
        address_wallet: "Адреса гаманця",
        refill_commission: "Комісія на поповнення становить {0}% + {1} USDT",
        min_amount: "Мінімальна сума поповнення {0} USDT",
        warning:
          "<b>Ретельно</b> перевірте, що введений вами гаманець збігається з реквізитами. Сума переказу буде нарахована вам протягом 15-30 хвилин",
      },
    },
    refunded: "Возмещено",
    selected_mediabuyer_from_to: "Медиабайеров {0} / {1}",
    selected_from_to: "Обрано {0} / {1}",
    selected_count_label_1: "Обрано {0} {1}",
    selected_count_label_2: "Обрано {0} {1}",
    hide: "Приховати",
    view_cards: "Подивитися картки",
    back_to_cards: "Повернутися до карток",
    show_all_cards: "Показат усі картки",
    choose_bank: "Оберіть банк",
    choose_or_add_card_group: "Виберіть або додайте нову групу",
    amount_debited_from_your_balance: "Сума, яка буде списана з вашого балансу",
    transfer_from: "Від",
    transfer_to: "Кому",
    set: "Встановити",
    set_limit: "Встановити ліміт",
    money_transaction: "Переказ коштів",
    delete: "Видалити",
    activate: "Відновити",
    to_refill: "Поповнити",
    transfer: "Переклад",
    team_statistics: "Статистика команди",
    lang: "Мова",
    refill: "Поповнення",
    close_card: "Закрити картку",
    pause_card: "Пауза",
    play_card: "Снять з паузами",
    unfreeze_card: "Розморозити картку",
    unfreeze: "Розморозити",
    issue_card: "Випуск картки",
    issue_limit_card: "Випуск лімітної картки",
    filters: "Фільтри",
    settings: "Налаштування",
    table_columns: "Колонки у таблиці",
    clear: "Очистити",
    clear_all: "Очистити",
    choose_all: "Обрати усе",
    search: "Пошук",
    card_n: "Картка {0}",
    submit: "Відправити",
    show: "Показати",
    show_all: "Показать все",
    accept: "Прийняти",
    close: "Закрити",
    save: "Зберегти",
    cancel: "Скасувати",
    yes: "Так",
    no: "Ні",
    card_deposit: "Поповнення картки",
    transfer_to_balance: "Перевод на баланс",
    bank_info: {
      odin: {
        auto_closed_during_one_month:
          "Зверніть увагу, що картки з даного BIN автоматично зупиняються. якщо протягом місяця не було транзакцій.",
      },
    },
    transaction: {
      status: {
        approved: "Погоджено",
        settled: "Врегульовано",
        declined: "Відмовлено",
        pending: "В ожидании",
      },
      type: {
        payment: "Оплата",
        refund: "Повернення",
        unknown: "Інші",
        authorization: "Авторизація",
        refill: "Надходження",
        commission: "Комісія",
      },
      cardType: {
        prepaid: "Балансові",
        postpaid: "Лімітні",
      },
    },
    cash_flow: {
      type: {
        funds_transfer: "Переказ грошей",
        card_issue: "Комісія",
        transaction_fee: "Комісія за транзакцію",
        int_transaction_fee: "Комісія за міжнародну транзакцію",
        card_deposit: "Поповнення картки",
        funds_deposit: "Поповнення внутрішнього балансу",
        incoming_funds_transfer: "Вхідний переказ грошей",
        transaction_refund: "Повернення коштів за транзакцією",
        funds_exchange: "Конвертація валюти",
        decline_fee: "Комісія за відхилений платіж",
        card_close: "Закриття картки. Повернення невикористаних коштів",
        transaction_refund_to_balance:
          "Повернення за транзакцією із зачиненої картки",
        transaction_refunded_fee: "Компенсація комісії за повернені платежі",
        card_withdraw: "Перевод с карты на основной баланс",
      },
    },
    currency_converter: {
      title: "Конвертер валют",
      from_currency: "З валюти",
      to_currency: "У валюту",
      amount: "Сума",
      receive_amount: "Сума, яку ви отримаєте",
      exchange_rate_valid: "Обмінний курс дійсний {0}с",
      convert: "Конвертувати",
      are_you_sure: `Ви впевнені, що хочете конвертувати <span class="text-no-wrap">{0} ⭢ {1} ?</span>`,
      successful_conversion: "Конвертація пройшла успішно",
    },
    table: {
      header: {
        on_hold: "На утримуванні",
        from: "Від",
        to: "У",
        closed_by: "Зачинено",
        income: "Надходження",
        comment: "Коментар",
        role: "Role",
        bank: "Банк",
        banks: "Банки",
        expire: "Дійсний до",
        name: "Ім'я",
        number_of_cards: "Кількість карток",
        number_of_active_cards: "Кількість активних карток",
        expenditure: "Витрати",
        date: "Дата",
        card_number: "Номер картки",
        issued_at: "Випущено",
        status: "Статус",
        cardType: "Тип картки",
        owner: "Власник",
        spend: "Витрачено",
        balance: "Баланс",
        card_balance: "Картковий баланс",
        currency: "Валюта",
        amount: "Сума",
        transaction_fee: "Комісія за інші транзакції",
        another_transaction_fee: "Комісія за міжнародні транзакції",
        additional_transaction_fee_another_currency:
          "Дод. комісія за міжнародні транзакції",
        transaction_fee_with_currency: "Комісія за транзакції ({0})",
        min_transaction_fee: "Miн комісія за транзакцію",
        decline_transaction_fee: "Комісія за відхилені транзакції",
        fixed_fee_for_any_operation: "Фикс. комиссия за любую операцию",
        commission_rate: "Коміссія",
        commission_rate_for_cards: "Комиссия за карты",
        type: "Тип",
        description: "Описання",
        wallet: "Гаманець",
        account: "Аккаунт",
        limit_amount: "Сума ліміту",
        payment_method: "Платіжна система",
        payments: "Оплати",
        refunds: "Повернення",
        transactions: "Транзакції",
        transactions_amount: "Транзакции (кол-во)",
        statistic_amount: "Кол-во",
        statistic_decline: "Отклонено",
        statistic_decline_amount: "Отклонено (кол-во)",
        statistic_pending: "Одобрено | В обработке",
        statistic_pending_amount: "Одобрено | В обработке (кол-во)",
        statistic_refunded: "Возвращено",
        statistic_refunded_amount: "Возвращено (кол-во)",
        statistic_approved: "Одобрено | Урегулировано",
        statistic_approved_amount: "Одобрено | Урегулировано (кол-во)",
        declined: "Відхилені",
        total: "Разом",
        numTransaction: "Кількість транзакцій",
        numDeclinedTransaction: "Кількість відхилених транзакцій",
        daily_spend: "Щоденні витрати",
        daily_limit: "Щоденний ліміт",
        group_name: "Група",
        total_spend: "Загальні витрати",
        total_limit: "Загальний ліміт",
        bin_name: "Назва BIN",
        bin_id: "BIN ID",
        zip_info: "ZIP-інформація",
        recommendation: "Рекомендуется",
        supervisor: "Керівник",
      },
    },
    sidebar: {
      profile: "Профіль",
      cards: "Картки",
      reports: "Звіти",
      my_balance: "Мій баланс",
      referral_program: "Реф. программа",
      converter: "Конвертер",
      transactions: "Транзакції",
      cash_flow: "Потік коштів",
      refill: "Поповнення",
      team: "Команда",
      statistic: "Статистика",
      statistic_by_users: "Користувачі",
      statistic_by_cards: "Картки",
      statistic_by_status: "По статусу",
      statistic_by_type: "По типу",
      support: "Підтримка",
      card_with_limit: "З лімітом",
      card_with_balance: "З балансом",
      partners: "Партнери",
    },
    profile: {
      notification: {
        by_email: "З email",
        by_telegram: "В Telegram",
      },
    },
    cards: {
      title: "Картки",
      status: {
        active: "Активний",
        closed: "Закритий",
        freeze: "Пауза",
        suspend: "Заморожено",
      },
    },
    faq: {
      title: "Часто задавані питання",
    },
  },
};
