import { RootState } from "@store/types";
import { GetterTree } from "vuex";
import { ProfileState } from "../types";
import profileAvatar from "./profileAvatar";
import profileEmail from "./profileEmail";
import profileId from "./profileId";
import profileFirstName from "./profileFirstName";
import profileFreeCardsTotalPromocode from "./profileFreeCardsTotalPromocode";
import profileFullName from "./profileFullName";
import profileAuthority from "./profileAuthority";
import profileLanguage from "./profileLanguage";
import profilePromocode from "./profilePromocode";
import profileSecondFactorEnabled from "./profileSecondFactorEnabled";
import profileTeamleadEmail from "./profileTeamleadEmail";
import profilePromocodes from "./profilePromocodes";
import profilePromocodesLoading from "./profilePromocodesLoading";
import cardIssueRemainingLimit from "./cardIssueRemainingLimit";
import creditCardIssueLimitExceeded from "./creditCardIssueLimitExceeded";
import postpaidCardIssueRemainingLimit from "./postpaidCardIssueRemainingLimit";
import cardIssueLimitExceeded from "./cardIssueLimitExceeded";
import canViewTeamPage from "./canViewTeamPage";
import canInviteReferrals from "./canInviteReferrals";
import canViewFacebookCodeNotifications from "./canViewFacebookCodeNotifications";
import canViewTeamCardData from "./canViewTeamCardData";
import accessToLimitCardsPage from "./accessToLimitCardsPage";
import accessToProfilePage from "./accessToProfilePage";
import userIsTeamlead from "./userIsTeamlead";
import userRegDate from "./userRegDate";
import userIsAccountant from "./userIsAccountant";
import userIsMediabuyer from "./userIsMediabuyer";
import userIsOwner from "./userIsOwner";
import userHasRole from "./userHasRole";

const getters: GetterTree<ProfileState, RootState> = {
  profileAvatar,
  profileEmail,
  profileId,
  profileFirstName,
  profileFreeCardsTotalPromocode,
  profileFullName,
  profileAuthority,
  profileLanguage,
  profilePromocode,
  profileSecondFactorEnabled,
  profileTeamleadEmail,
  profilePromocodes,
  profilePromocodesLoading,
  cardIssueRemainingLimit,
  creditCardIssueLimitExceeded,
  postpaidCardIssueRemainingLimit,
  cardIssueLimitExceeded,
  canViewTeamPage,
  canInviteReferrals,
  canViewFacebookCodeNotifications,
  accessToLimitCardsPage,
  accessToProfilePage,
  canViewTeamCardData,
  userIsTeamlead,
  userRegDate,
  userIsAccountant,
  userIsMediabuyer,
  userIsOwner,
  userHasRole,
};

export default getters;
